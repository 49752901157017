@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html {
        overflow: hidden;
        height: 100%;
        width: 100%;
    }
    body {
        overflow: auto;
        height: 100%;
        width: 100%;
    }
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-image: url("../img/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
}

.item {
    display: block;
}
